import React from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import PlaceholderImage from "assets/images/thumbnail-portrait-generic.jpg";
import {Breadcrumb, Button, ExternalIcon, SvgIcon } from "components/ui";
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import styled from "styled-components";
import {Link} from "gatsby";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"


//default layout for resource detail pages
const ResourceEntry = ({ data, location }) => {
  const createLink = (breadcrumbs)=>{
    const breadcrumbsArray = breadcrumbs.split("/");
    const length = breadcrumbsArray.length - 1;
    let path="";
    for(let i=0; i<=length-2; i++){
        breadcrumbsArray[i] !== "" && (path=path.concat("/" + breadcrumbsArray[i]));
    }
return path;
}
    const resource = data.strapiResource

    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }
    const seo = {
        metaTitle: resource?.title,
        metaDescription: resource?.description,
        shareImage: getSrc(resource?.thumbnail?.localFile),
        metaKeywords: resource?.keywords
    }
    const DefaultLayoutWrapper = styled.div`
    .usa-breadcrumb{
        padding-bottom: 0;
    }
`;
  return (
    <Layout location={location}>
        <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
        <DefaultLayoutWrapper className="grid-container">
            <Breadcrumb pathname={thisLocationPathname} customCurrentPage={seo.metaTitle} />
            <h1 name="main-content" id="main-content">{resource?.title}</h1>
            <div className="padding-bottom-5">
                <div className="grid-row">
                    <div className="grid-col-auto maxw-card-lg mobile:grid-col-3">
                      {resource.thumbnail ?
                        <GatsbyImage
                            image={getImage(resource?.thumbnail?.localFile)}
                            alt={resource?.thumbnail?.alternativeText ?? "" }
                            imgStyle={{ width: "100%", height: "100%" }}
                            style={{width: "236", height: "307"}}
                            className="border-2px border-base-lighter"
                        />
                      :
                        <img
                          src={PlaceholderImage}
                          className="border-2px border-base-lighter"
                          style={{width: "236", height: "307"}}
                          alt=""
                        />
                      }
                    </div>
                  <div className="tablet:grid-col-8 mobile:grid-col-8 margin-left-3 tablet:margin-left-5">
                    {(resource?.author || resource?.resource_date) && <p className="text-bold">{`${resource?.author ? resource?.author : ""}${(resource?.author && resource?.resource_date) ? ", " : ""}${resource?.resource_date ? resource?.resource_date?.slice(-4): ""}`}</p>}
                    {resource?.body &&
                    <div
                        dangerouslySetInnerHTML={{
                        __html: resource?.body?.data?.childMarkdownRemark?.html,
                        }}
                    />
                    }
                    {resource?.pdf ?
                    <>
                      <Button color="primary-button" size="padding-x-3" link={`/files/${resource?.pdf?.name}`}>{`View Document (PDF ${resource?.pdf && resource?.pdf?.localFile?.prettySize.toUpperCase()})`}</Button>
                      {resource?.external_resource &&
                        <ExternalIcon />
                      }
                    </>
                    : resource?.external_url_resource &&
                    <>
                      {resource?.external_file_size ?
                        <a href={resource?.external_url_resource && resource?.external_url_resource} className="usa-button primary-button padding-x-5"  aria-label={`Open ${resource?.title} in a new window`} target="_blank" rel="noreferrer">
                          View Document { resource?.external_file_size &&` (PDF ${resource?.external_file_size.toUpperCase()})`}
                        </a>
                        :
                        <a href={resource?.external_url_resource && resource?.external_url_resource} className="usa-button primary-button padding-x-5"  aria-label={`Open ${resource?.title} in a new window`} target="_blank" rel="noreferrer">
                          View Website
                        </a>
                      }
                      {resource?.external_resource && <ExternalIcon />}
                    </>
                    }
                    {
                    resource?.topics?.length > 0 &&
                      <p className="margin-bottom-0">Topic(s): {resource?.topics?.reverse().map((topic, idx)=>{
                        return idx === 0 ? topic?.title : ", " + topic?.title
                        })}
                      </p>
                    }
                    {
                    resource?.category &&
                      <p className={`${resource?.topics?.length > 0 ? "margin-top-0": "" }`}>Section: {resource?.category.name}</p>
                    }
                    {
                    (((!thisLocationPathname.includes("whats-new")) && resource?.series?.length > 0) || ((thisLocationPathname.includes("whats-new/harm-reduction-series")||thisLocationPathname.includes("whats-new/safety-risk-guides-series")||thisLocationPathname.includes("whats-new/cannabis-series")||thisLocationPathname.includes("whats-new/ICWA-active-efforts-series")||thisLocationPathname.includes("whats-new/trauma-toolkit-series")) && resource?.series?.length > 0)) &&
                      <div className="margin-top-6">
                        <Link to={createLink(thisLocationPathname)}>
                          <SvgIcon name="arrow_back" />Back to series
                        </Link>
                      </div>
                    }
                  </div>
                </div>
              </div>
          </DefaultLayoutWrapper>
    </Layout>
    );

};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiResource(slug: { eq: $slug }) {
        id
        slug
        title
        description
        keywords
        author
        external_resource
        external_url_resource
        external_file_size
        open_external_in_new_window
        resource_date(formatString: "MMMM DD, YYYY")
        body {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        thumbnail {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        pdf {
          name
          localFile {
            prettySize
          }
        }
        category {
          name
        }
        topics {
          title
        }
        series{
          title
        }
    }
  }
`

export default ResourceEntry;
